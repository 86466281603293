import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SlickSlider from "react-slick";
import CarouselArrowLeft from "../images/Capacity_ArrowCarousel_Left.svg";
import CarouselArrowRight from "../images/Capacity_ArrowCarousel_Right.svg";

import styles from "./slider.module.css";
import { cn } from "../lib/helpers";

const Slider = (props) => {
  const { children, bottomMargin, widthLimit } = props;

  const PrevArrow = ({ onClick }) => (
    <button
      type="button"
      onClick={onClick}
      title="Previous"
      className={cn(styles.prevArrow, styles.carouselArrow)}
    >
      <img src={CarouselArrowLeft} alt="CarouselArrowRight" />
    </button>
  );
  const NextArrow = ({ onClick }) => (
    <button
      type="button"
      onClick={onClick}
      title="Next"
      className={cn(styles.nextArrow, styles.carouselArrow)}
    >
      <img src={CarouselArrowRight} alt="CarouselArrowRight" />
    </button>
  );

  const settings = {
    dots: false,
    infinite: true,
    accessibility: true,
    adaptiveHeight: true,
    fade: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    cssEase: "cubic-bezier(0.25, 1, 0.1, 1)",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    useTransform: false,
  };

  return (
    <div
      className={cn(
        styles.root,
        bottomMargin && styles.bottomMargin,
        widthLimit && styles.widthLimit
      )}
    >
      <SlickSlider {...settings}>
        {children.map((slide, index) => {
          return (
            <div className={styles.slideWrapper} key={index}>
              {slide}
            </div>
          );
        })}
      </SlickSlider>
    </div>
  );
};

export default Slider;
